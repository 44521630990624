import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import SEO from '../components/seo';
import Layout from '../components/layout';

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Content>
        <Wrapper>
          <SEO
            title={`Shophilist App | ${frontmatter.title}`}
            keywords={[
              `shophilist`,
              `mobile app`,
              `app`,
              `shopping list`,
              `groceries`
            ]}
          />
          <Link to="/">← Front page</Link>
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <Link to="/">← Front page</Link>
        </Wrapper>
      </Content>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

const Content = styled.div`
  background-color: #fff6b9;
  width: 100vw;
  margin: 0 auto;
  flex: 1;
`;

const Wrapper = styled.div`
  background-color: #fff6b9;
  padding: 0 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 34rem;
  margin: 0 auto;
  color: #607d8b;
  word-wrap: break-word;
`;
